import { helpers } from "@vuelidate/validators";

export default function (params) {
  let validator = (value) => {
    try {
      params.list.includes(value);
    } catch (e) {
      utilities.debug(
        "inList validator - error list.includes exception - list is probably not an array",
        2
      );
      return false;
    }
    return params.list.includes(value);
  };

  return helpers.withParams({ type: "inList" }, validator);
}
